import React from "react";
import { Alert, Col, Form, Button, Container } from "react-bootstrap";
import moment from "moment";
import { Promise } from "bluebird";
import Caller from "./caller";
import CrmCallForm from "./crmCallForm";
import NoteInput from "../shared/noteInput";
import OneClickSignUpForm from "./oneClickSignUpForm";
import DocumentTitle from "../shared/documentTitle";
import { postAPI } from "utils/requestAPI";
import { getAPI } from "utils/requestAPI";

class CallForm extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
            branch: null,
            vendorProp: null,
            phoneNum: null,
            vpId: null,
            twilioToken: null,
            loading: true,
            warn: false,
            warnMsg: "",
            saveToBranch: false,
            callerReady: false,
			crmCallId: null,
			showScheduleCrmCallOption: false,
			clientCallId: null,
		};

		this.fetchCallData = this.fetchCallData.bind(this);
		this.fetchTwilioToken = this.fetchTwilioToken.bind(this);
		this.handlePhoneNumChange = this.handlePhoneNumChange.bind(this);
		this.handleSaveToBranch = this.handleSaveToBranch.bind(this);
		this.handleOnCallEnd = this.handleOnCallEnd.bind(this);
		this.handleStartCall = this.handleStartCall.bind(this);
	}

	componentDidMount()
	{
		this.setState({loading: true});

		Promise.join(this.fetchCallData(), this.fetchTwilioToken())
			.then(() => this.setState({loading: false}));
	}

	fetchCallData()
	{
		const { vpId, branchId } = this.props.match.params;

		if(branchId && vpId)
		{
			return Promise.join(
				getAPI(`/admin/branch/${branchId}.json?include=admins`)
					.then(({branch}) => this.setState({ branch, phoneNum: branch.phone }))
					.catch(e => alert(e || "An error occurred."))
				,
				getAPI(`/admin/vendor/property/${vpId}/edit.json`)
					.then(({vendorProp}) => this.setState({ vendorProp }))
				,
				getAPI(`/admin/vendor/CRMTeam/${vpId}/CRMCall/${branchId}`)
					.then(({crmCallId, showScheduleCrmCallOption}) => this.setState({ crmCallId, showScheduleCrmCallOption }))
					.catch(e => alert(e || "An error occurred."))
			);

		}
		else if(vpId)
		{
			return getAPI(`/admin/vendor/property/${vpId}/edit.json`)
				.then(({vendorProp}) => this.setState({ vendorProp, phoneNum: vendorProp.user.mobile }))
				.catch(e => alert(e || "An error occurred."));

		}
		else if(branchId)
		{
			return getAPI(`/admin/branch/${branchId}.json?include=admins`)
				.then(({branch}) => this.setState({ branch, phoneNum: branch.phone }))
				.catch(e => alert(e || "An error occurred."));
		}
		else
		{
			alert("Neither a branch nor a vendor was provided, so this isn't going to work!");
			return false;
		}


	}

	fetchTwilioToken()
	{
		return getAPI("/admin/twilioToken.json")
			.then(({ twilioToken }) => this.setState({ twilioToken}))
			.catch(e => alert(e || "An error occurred."));
	}

	handlePhoneNumChange(e)
	{
		this.setState({ phoneNum: e.target.value });
	}

	handleSaveToBranch()
	{
		if(!this.state.saveToBranch && this.state.phoneNum)
		{
			//Make request to update number
			postAPI(`/admin/branch/${this.state.branch.id}/edit`, { phone : this.state.phoneNum.trim() })
				.catch(e => alert("Error: could not update this branch phone number"));
		}

		if(!this.state.saveToBranch && !this.state.phoneNum)
		{
			alert("No Phone number to save to branch");
			return;
		}

		// Set the state for the view
		this.setState({ saveToBranch: !this.state.saveToBranch });
	}

	handleStartCall()
	{
		if(this.state.phoneNum)
		{
			this.setState({ startCall: true, callerReady: false }, () =>
			{
				this.setState({ startCall: false });
			});
		}
		else
		{
			alert("No number to call...");
		}
	}

	handleOnCallEnd()
	{
		this.setState({ callerReady: true });

		if(this.state.crmCallId)
			this.handleEndCRMCall();
	}

	setCallClientId(id)
	{
		this.setState({ clientCallId: id });
	}

	renderBranchAdminOrSignUpForm(branch, vendorProp)
	{
		if(branch && branch.admins)
		{
			if(branch.admins.length > 1)
			{
				return  (
					<>
						<strong>Branch Admins:</strong>
						<ul>
							{branch.admins.map(brAdmin => <li>{brAdmin.name}</li>)}
						</ul>
					</>
				);
			}
			else if(branch.admins.length === 1)
			{
				return (
					<>
						<strong>Branch Admin: </strong>{branch.admins[0].name}
					</>
				);
			}
			else
			{
				return <OneClickSignUpForm branch={branch} vendorProp={vendorProp} />;
			}

		}
	}

	handleEndCRMCall = async() => {
		try
		{
			await postAPI("/admin/vendor/CRMTeam/updateCRMCall", {
				crmCallId: this.state.crmCallId,
				clientCallId: this.state.clientCallId
			});
		}
		catch(err)
		{
			alert("Error while updating CRM call details after end of call: " + err.message);
		};
	}

	render()
	{

		const { branch, vendorProp, phoneNum, loading, warn, warnMsg } = this.state;

		if(loading)
			return <Alert variant="info">Loading data, please wait...</Alert>;

		return (
			<Container className="mt-3">
				<DocumentTitle title={"Call" + (branch ? branch.name : vendorProp ? vendorProp.user.name : null)} />
				{/* Do not call error message. */}
				{branch && branch.do_not_call_until && moment(branch.do_not_call_until).isAfter(moment()) &&
            <Alert variant="danger" style={{ marginTop: "5px"}}> Be careful! This branch has been marked as do not call...</Alert>}

				{/* Call form title */}
				<h3>Call {branch ? branch.name : vendorProp ? vendorProp.user.name : null}</h3>

				<Form className="my-4">
					<Form.Row>
						<Col xs={8}>
							<Form.Label>Phone Number:</Form.Label>
							<Form.Control
								type="text"
								value={phoneNum}
								onChange={this.handlePhoneNumChange}
							/>
						</Col>
						<Col xs={4}>
							<Form.Label>&nbsp;</Form.Label>
							<Button
								variant="success"
								style={{width:"100%"}}
								onClick={this.handleStartCall}
								disabled={!this.state.callerReady}

							>
                            Ring Ring!
							</Button>
						</Col>
					</Form.Row>

					{branch && (
						<Form.Row className="mt-2">

							<Col xs={12}>
								<Button
									disabled={this.state.saveToBranch}
									onClick={this.handleSaveToBranch}
									size="sm">
									{this.state.saveToBranch ? "Done" : "Save to branch?"}
								</Button>
							</Col>

						</Form.Row>
					)}
				</Form>


				{this.renderBranchAdminOrSignUpForm(branch, vendorProp)}

				<Caller
					branch={branch}
					vendorProp={vendorProp}
					phoneNum={this.state.phoneNum}
					callerReady={(ready) => this.setState({callerReady:ready})}
					twilioToken={this.state.twilioToken}
					startCall={this.state.startCall}
					onCallEnd={this.handleOnCallEnd}
					setCallClientId={this.setCallClientId.bind(this)}
				/>

				<NoteInput
					branch={branch}
					vendorProp={vendorProp}
					allowReminder
					className="my-3"
				/>

				{this.state.crmCallId ? <CrmCallForm crmCallId={this.state.crmCallId} showScheduleCrmCallOption={this.state.showScheduleCrmCallOption}/> : null}

			</Container>
		);
	}
}

export default CallForm;
