import React, { useState } from 'react';
import { Card, Row, Col, FormControl, Form, InputGroup, Collapse, Button, Spinner } from 'react-bootstrap';
import VendorSaleUpdates from '../shared/vendorSaleUpdates';
import _ from 'underscore';
import moment from 'moment';
import VpSaleUpdateCallback from './vpSaleUpdateCallback';
import { postAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";

const PropertySaleUpdate = ({ billingEmail, setNewBillingEmail, vendor, outcomes, hasOutcomeInMonth, branchId, callClientId }) =>
{
	const { id, address1, estvalue, user: { name }} = vendor;

	const [saving, setSaving] = useState(false);
	// make the button non clickable after success post
	const [saved, setSaved] = useState(false);
	
	// we extract this bit here from props as we will show this as UI/UX for user feedback on save
	const [hasOutcome, setHasOutcome] = useState(hasOutcomeInMonth);

	const [ outcome, setOutcome ] = useState("");
	const [ exchangePrice, setExchangePrice ] = useState(estvalue);
	const [ exchangeDate, setExchangeDate ] = useState(moment().format('YYYY-MM-DD'));

	const [vendorNote, setVendorNote] = useState("");
	
	// make a local update status
	const couldNotGetUpdate = 999;

	const cardTitle = () => (
		<>
			<a className="text-primary" href={adminPanelURL(`/vendor/property/${id}/edit`)} target="_blank">{name}</a>
			<p className="d-inline-block mx-3 my-0">|</p>
			<p className="d-inline-block my-0">{address1}</p>
		</>
	);

	const outcomeChanged = (e) => setOutcome(parseInt(e.target.value));

	const outcomesArray = _.keys(_.omit(outcomes, 'NOT_NEEDED'));

	const saveUpdateForProp = async () => 
	{
		// check that we have a call client id
		if(! callClientId)
			return alert("No callClientId found. Did you try making a call first? If true, please screenshot error and report to line manager.");

		setSaving(true);
		
		// Check if there is a note and if true,
		// we save note first as we will need noteId later 
		let noteId = null;
		
		if(vendorNote.length)
		{
			const noteObj = 
			{
				note: vendorNote,
				vendor_prop_id: id
			};

			noteId = await saveNote(noteObj);
		}

		const postData = {
			callClientId,
			status: outcome
		};

		if(noteId)
			postData.noteId = noteId;
		
		if(outcome === outcomes.EXCHANGED)
		{
			postData.estValue = exchangePrice;
			postData.date = exchangeDate;
			postData.billing_email = billingEmail
		}

		try
		{
			const res = await postAPI(`/vendor/property/${id}/saleUpdate/${branchId}`, postData);
	
			setSaving(false);
		}
		catch(err)
		{
			setSaving(false);
			return alert('We got an error on the server while saving the update. Please screenshot and report to your line manager.');
		}

		// some UX validation
		setSaved(true);
		setHasOutcome(true);

	}

	const saveNote = async (noteObj) =>
	{
		const { success, id } = await postAPI('/admin/branch/' + branchId + "/note", noteObj);
		
		if(! success)
		{
			alert('Could not save note, will still try to update everything else.')
			return false;
		}
		
		return id;	
	}


	return (
	
		<Card style={{backgroundColor: hasOutcome ? "aquamarine" : null}}>
			<Card.Title className="p-3 d-flex">
				{ cardTitle() }
			</Card.Title>

			<Card.Body>

				<Row>
					<Col md={6}>
							<Row className="align-items-center">
								<Col md={3}>
									<Form.Label>Outcome:</Form.Label>
								</Col>
								<Col md={9}>
									<FormControl 
										as="select"
										onChange={outcomeChanged}
										value={outcome ? outcome : ''}
									>
										<option value="0">Select...</option>
										<option value={couldNotGetUpdate}>COULD_NOT_GET_UPDATE</option>
										{outcomesArray.map((o, i) => <option value={outcomes[o]} key={i}>{o}</option>)}
									</FormControl>
								</Col>
							</Row>
							{outcome === outcomes.EXCHANGED && 
								<>
									{/*// final price*/}
									<Row className="align-items-center my-2">
										<Col md={3}>
											<Form.Label>Sale price:</Form.Label>
										</Col>
										<Col md={9}>
											<InputGroup>
												<InputGroup.Prepend>
													<InputGroup.Text>£</InputGroup.Text>
												</InputGroup.Prepend>
												<Form.Control
													type="number"
													value={exchangePrice}
													onChange={(e) => setExchangePrice(parseInt(e.target.value))}
												/>
											</InputGroup>
											
										</Col>
									</Row>

									{/*// completes on*/}
									<Row className="align-items-center my-2">
										<Col md={3}>
											<Form.Label>Completes on:</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Control
												type="date"
												value={exchangeDate}
												onChange={(e) => setExchangeDate(moment(e.target.value).format('YYYY-MM-DD'))}
											/>
										</Col>
									</Row>
									{/*// bill address*/}
									<Row className="align-items-center my-2">
										<Col md={3}>
											<Form.Label>Invoice email:</Form.Label>
										</Col>
										<Col md={9}>
											<Form.Control
												type="text"
												value={billingEmail}
												onChange={(e) => setNewBillingEmail(e.target.value)}
											/>
										</Col>
									</Row>
								</>
							}
							{(outcome && (outcome !== couldNotGetUpdate)) ? // here we want to show the plain text note
								<Row className="my-3 justify-content-end">
									<Col md={9}>
										{/*leave note on top level branch */}
										<FormControl 
											as="textarea" 
											placeholder="Optional note goes here" 
											rows="3" 
											value={vendorNote} 
											onChange={(e) => setVendorNote(e.target.value)}
										/>
									</Col>
								</Row> : null
							}


							{outcome === couldNotGetUpdate && // could not get update on this call
								<VpSaleUpdateCallback 
									vendorId={id} 
									branchId={branchId} 
									verticalMargin="my-4" 
									updateFunction={(has) => setHasOutcome(has)} 
								/>
							}
					</Col>
					<Col md={6}>
						<VendorSaleUpdates
							updates={3} 
							saleUpdates={vendor.vpSaleUpdates} 
							saleOutcomes={outcomes} 
							showUpdateLink={false} 
							className="mb-3"
							sstcDate={vendor.sstcDate}
						/>
					</Col>
				</Row>


			</Card.Body>
			{/*so this button dissapears if saved and success*/}
			<Collapse in={ !!(outcome && outcome !== couldNotGetUpdate) && !saved }>
				<Card.Footer>
					<Button 
						onClick={saveUpdateForProp} 
						variant="success" 
						className="btn-block" 
						>{saving ? <Spinner size="md" variant="primary" animation="border" /> : 'Save'}</Button>
				</Card.Footer>
			</Collapse>
		</Card>
		
	)
}

export default PropertySaleUpdate;