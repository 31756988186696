import React from "react";
import { Alert, Button, Col, Row, Card, Badge, FormControl } from "react-bootstrap";
import {GAConfigContext} from "../../contexts/gaConfigContext";
import { FaTimesCircle, FaHeadphonesAlt } from "react-icons/fa";
import ShortlistEdit from "./shortlistEdit";
import VendorTimeline from "./vendorTimeline";
import { getAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";
import moment from "moment";
import {MdOpenInNew} from "react-icons/md";

class CRMTeamVendorProp extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			vendorProp: props.vendorProp,
			loading: true,
			CRMUser: null
		};
	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		this.getVendorProp();
	}

	refreshVendorProp = () =>
	{
		this.setState({refreshing: true});

		const endFunc = () => this.setState({refreshing: false});

		this.getVendorProp(endFunc);
	}

	getVendorProp = async(callback) =>
	{
		const { vpId } = this.state.vendorProp;

		this.setState({loading: true});

		try
		{
			const response = await getAPI(`/admin/vendor/property/${vpId}/edit.json`);

			this.setState({
				vendorProp: {...this.state.vendorProp, ...response.vendorProp},
				loading: false
			});
		}
		catch (e)
		{
			throw new Error("Could not load vendor:", e.message);
		}
	}

	render()
	{
		const {
			branchBlocker,
			transferValuation,
			CRMUsers
		} = this.props;

		const {
			vendorProp,
			loading,
			CRMUser
		} = this.state;

		const poundFormat = new Intl.NumberFormat("en-GB", {
			style: "currency",
			currency: "GBP",
			minimumFractionDigits: 0
		});

		if(CRMUsers.length > 0 && !CRMUser)
			this.setState({ CRMUser: CRMUsers[0].id });

		return vendorProp ? (
			<Card className="my-4">
				<Card.Header style={{backgroundColor: "#fbe6b2", display: "flex", alignItems: "flex-end"}}>
					<Card.Title style={{fontSize: "20px"}}>
						{vendorProp.bName}

						<Badge variant="primary" className="float-right">
							<FaHeadphonesAlt style={{marginRight: "5px"}} />
							{vendorProp.callType}
						</Badge>
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col xs={12}>
							<Row style={{marginBottom: "8px"}}>
								<Col sm={6}>
									<p>
										<a href={adminPanelURL("/branch/" + vendorProp.bId + "/edit")} target="_blank">
											View agent's page&nbsp;
											<MdOpenInNew />
										</a>
									</p>
								</Col>
								<Col sm={6}>
									<Row>
										<Col sm={12} style={{ display: "flex", justifyContent: "flex-end" }}>
											<Button
												variant="danger"
												onClick={branchBlocker(vendorProp.bId, vendorProp.vpId)}
											>
												<FaTimesCircle style={{marginRight: "5px"}}/>
											CRM team won't chase
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									<p><strong>CC valuations emails:</strong><br/>
										{vendorProp.valuation_email}</p>
								</Col>
								<Col sm={6} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									Transfer to:
									<FormControl as="select" placeholder="select" onChange={(e) => this.setState({ CRMUser: e.target.value })} style={{ width: "200px", marginLeft: "8px" }}>
										{CRMUsers.map((user, i) => <option key={i} value={user.id}>{user.name}</option>)}
									</FormControl>
									<Button
										variant="warning"
										onClick={transferValuation(vendorProp.callId, CRMUser)}
										style={{ marginLeft: "8px" }}
									>
										Save
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card.Body>


				<Card.Body style={{borderTop: "2px solid #F9FBFD"}}>
					{loading ? <Alert variant="info">Loading vendor details...</Alert> : (
						<>
							<Card className="my-2">
								<Card.Body>
									<Row>
										<Col xs={12}>
											<Row>
												<Col sm={6}>
													<p><strong>Name:</strong><br/>
														{vendorProp.user && vendorProp.user.name}</p>
												</Col>
												<Col sm={6}>
													<a href={adminPanelURL(`/vendor/property/${vendorProp.id}/edit`)} target="_blank">
														View vendor's page&nbsp;
														<MdOpenInNew />
													</a>
												</Col>
											</Row>
											<Row>
												<Col sm={6}>
													<p><strong>Address:</strong><br/>
														{[vendorProp.address1, vendorProp.postcode.toUpperCase()].filter(str => str).join(", ")}</p>
												</Col>
												<Col sm={6}>
													<p><strong>Beds &amp; Value:</strong><br/>
														{vendorProp.bedrooms} bed &bull; {poundFormat.format(vendorProp.estvalue)}</p>
												</Col>
											</Row>
											<Row>
												<Col sm={6}>
													<p><strong>Valuation requested on:</strong><br/>
														{moment(vendorProp.valReqAt).format("DD MMM YYYY (HH:mm)")}</p>
												</Col>
												<Col sm={6}>
													<p><strong>Valuation sent on/don't call agent until:</strong><br/>
														{moment(vendorProp.valNagFrom).format("DD MMM YYYY (HH:mm)")}</p>
												</Col>
											</Row>
										</Col>
									</Row>
								</Card.Body>
							</Card>

							<Row>
								<Col xs={12}>
									{!vendorProp ? <Alert>Loading...</Alert> : null}

									{vendorProp && <ShortlistEdit className="my-4" vendorProp={vendorProp} reloadVendor={this.getVendorProp} highlightBranch={vendorProp.bId} branchBlocker={branchBlocker} showBranchAdmins />}

									{vendorProp && <VendorTimeline className="my-4" vendorProp={vendorProp} reloadVendor={this.getVendorProp} />}
								</Col>
							</Row>
						</>
					)}
				</Card.Body>
			</Card>
		) : null;
	}
}

export default CRMTeamVendorProp;