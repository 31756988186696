import React from "react";
import {Table, Nav, NavDropdown, Dropdown, Alert, Button, Spinner, Modal, Badge} from "react-bootstrap";
import moment from "moment";
import { getAPI } from "utils/requestAPI";
import { adminPanelURL } from "utils/common";

const callIcons = (callType) =>
{
	const noCalls = {
        READY_FOR_VALS_1: "☎️",
        READY_FOR_VALS_2: "☎️☎️",
        READY_FOR_VALS_3: "☎️☎️☎️",
        READY_FOR_VALS_4: "☎️☎️☎️☎️",
        PROSPECT_READY_FOR_VALS_1: "⛏️☎️",
        PROSPECT_READY_FOR_VALS_2: "⛏️☎️☎️",
        CALL_BACK_1: "⏪☎️",
        CALL_BACK_2: "⏪☎️☎️",
        CALL_BACK_3: "⏪☎️☎️☎️",
        AUTO_UPSELL_1: "🦌",
        AUTO_UPSELL_2: "🦌🦌",
        LL_8_WEEK: "🐍",
        REVISIT_1: "🛬",
        REVISIT_2: "🛬🛬"
	};

	return noCalls[callType];
};

const callNumberToType = (num) =>
{
	const callTypeMapping = {
        1: "READY_FOR_VALS_1",
        2: "READY_FOR_VALS_2",
        3: "READY_FOR_VALS_3",
        4: "READY_FOR_VALS_4",
        5: "PROSPECT_READY_FOR_VALS_1",
        6: "PROSPECT_READY_FOR_VALS_2",
        7: "CALL_BACK_1",
        8: "CALL_BACK_2",
        9: "CALL_BACK_3",
        10: "AUTO_UPSELL_1",
        11: "AUTO_UPSELL_2",
        12: "LL_8_WEEK",
        13: "REVISIT_1",
        14: "REVISIT_2"
	};

	return callTypeMapping[num];
};

const callNumberToName = (num) =>
{
	const callTypeMapping = {
        1: "Call 1",
        2: "Call 2",
        3: "Call 3",
        4: "Call 4",
        5: "Prospect Call 1",
        6: "Prospect Call 2",
        7: "Call Back 1",
        8: "Call Back 2",
        9: "Call Back 3",
        10: "Auto Upsell 1",
        11: "Auto Upsell 3",
        12: "LL 8 Week",
        13: "Revisit 1",
        14: "Revisit 2",
	};

	return callTypeMapping[num];
};

const durationString = (duration) =>
{
	if(duration > 60)
	{
		const mins = Math.floor(duration/60);
		let secs = Math.abs(duration) - (mins * 60);

		if(secs < 10 )
			secs = "0" + secs;

		return mins + ":" + secs;
	}
	else if(duration > 10)
	{
		return duration + " sec";
	}
	else
	{
		return "Very short";
	}
};

const callOutcomes = [ "DID_NOT_ANSWER", "ANSWERED_VALS", "ANSWERED_NO_VALS", "ANSWERED_CALL_BACK", "NOT_FOUND", "ADDRESS_INCOMPLETE", "WRONG_NUMBER"];


class FujisanCalls extends React.PureComponent
{

	constructor(props)
	{
		super(props);
		this.state =
		{
            callsMade: {},
            userId: "all",
            callId : "all",
            csUsers : "",
            outcomeId : "all",
            loadingCalls : false,
            maxCallsHit: false,
            includeQA: false,
            modalQACall: null
		};
	}

    componentDidUpdate = (prevProps) =>
    {
    	if(prevProps.to !== this.props.to || prevProps.from !== this.props.from)
    		this.fetchCallTrainingStats();
    }

    componentDidMount()
    {
    	this.fetchCallTrainingStats();
    }

    fetchCallTrainingStats = async() =>
    {
    	const { to, from } = this.props;

    	this.setState({loadingCalls: true});

    	const queryString = {
            from: from,
            to: to
    	};

    	if(this.state.callId !== "all")
    		queryString.type = callNumberToType(this.state.callId);

    	if(this.state.userId !== "all")
    		queryString.caller = this.state.userId;

    	if(this.state.outcomeId !== "all")
    		queryString.outcome = callOutcomes[this.state.outcomeId];

    	try
    	{
    		const data = await getAPI("/admin/vendor/customerService/callHistory.json", { data: queryString });

    		this.setState({
                callsMade: data.callsMade || {},
                csUsers: data.csUsers || {},
                loadingCalls: false,
                maxCallsHit: data.maxCallsHit,
                includeQA: data.includeQA
    		});
    	}
    	catch (e)
    	{
    		this.setState({errorMsg: e.message});
    	}

    }

    selectCallNo = (event) =>
    {
    	const {target: { id } } = event;

    	this.setState({callId: id || "all"}, () => this.fetchCallTrainingStats());
    }

    selectUser = (event) =>
    {
    	const {target: { id } } = event;

    	this.setState({userId: id || "all"}, () => this.fetchCallTrainingStats());
    }

    selectOutcome = (event) =>
    {
    	const {target: { id } } = event;

    	this.setState({outcomeId: id || "all"}, () => this.fetchCallTrainingStats());
    }

    render = () =>
    {
    	const { callsMade, csUsers, callId, userId, outcomeId, maxCallsHit, includeQA, modalQACall } = this.state;
    	const { from, to } = this.props;

    	if(this.state.loadingCalls)
    	{
    		return <div className="text-center mt-5">
    			<Spinner size="lg" variant="primary" animation="border"/>
    		</div>;
    	}

    	if(! callsMade?.length)
    		return <Alert variant="warning"> <strong>No calls match these filters</strong> <Button size="sm" className="float-right" onClick={() => this.setState({ callId: "all", userId: "all", outcomeId: "all" }, () => this.fetchCallTrainingStats())}>Clear filters</Button></Alert>;


    	const renderCallQAModal = () =>
    	{
    		const handleClose = () => this.setState({modalQACall: null});

    		if(! modalQACall)
    			return null;

    		return (
    		<Modal show={!! modalQACall} onHide={handleClose}  size="lg" centered>
    			<Modal.Header closeButton>
    				<Modal.Title>AI QA Summary <Badge variant="secondary" size="lg">{modalQACall.total_score}/40</Badge></Modal.Title>
    			</Modal.Header>
    			<Modal.Body className="p-4">
                        <Alert variant="primary">
                            <h3>Computers can be stupid</h3>
                            <p>The QA text and score are all generated by AI. Transcription errors and general computer mysteries means that it can be very very wrong. Please listen to the call to verify anything the AI has said.</p>
                        </Alert>
    					{modalQACall.recording && (
    						<div className="mb-4">
    							<audio className="audio-css w-100" controls preload="none" volume="1.0">
								Your browser does not support the <code>audio</code> element.
    								<source src={modalQACall.recording} type="audio/mp3" />
    							</audio>
    						</div>
    					)}
    					<div className="mb-4">
    						<h3 className="mb-3">Summary</h3>
    						<div className="bg-light p-3 rounded">
    							{modalQACall.qa.summary.split("\n").map((line, index) => (
    								<p key={index} className="mb-2">{line}</p>
    							))}
    						</div>
    					</div>
    					<div className="mb-4">
    						<h3 className="mb-3">Good points</h3>
    						<ul className="list-group">
    							{modalQACall.qa.good_points.split("\n").map((line, index) => (
    								<li key={index} className="list-group-item">{line}</li>
    							))}
    						</ul>
    					</div>
    					<div>
    						<h3 className="mb-3">Constructive points</h3>
    						<ul className="list-group">
    							{modalQACall.qa.constructive_points.split("\n").map((line, index) => (
    								<li key={index} className="list-group-item">{line}</li>
    							))}
    						</ul>
    					</div>
    				</Modal.Body>

    			<Modal.Footer>
    				<Button variant="secondary" onClick={handleClose}>
                    Close
    				</Button>
    			</Modal.Footer>
    		</Modal>);
    	};


    	return (
    		<>
    			{maxCallsHit && (<Alert variant="warning"> Too many calls returned, so results are truncated. Please try a more specific search.</Alert>)}
    			<Table  hover responsive="xl">

    				<colgroup align="center">
    					<col width="5"/>
    					<col width="16%"/>
    					<col width="11%"/>
    					<col width="20%"/>
    					<col width="11%"/>
    					<col width="12%"/>
    					<col width="18%"/>
    					{includeQA && <col width="7%"/>}
    				</colgroup>

    				<thead>
    					<tr className="table-info">
    						<th className="text-center "><span className="mb-1">ID</span></th>
    						<th className="text-center">
    							<Nav>
    								<NavDropdown title={ userId != "all" ? csUsers && csUsers.find(x => x.id == userId).name : "Beautiful People" }>
    									<Dropdown.Item onClick={this.selectUser} id="all" >Beautiful People</Dropdown.Item>

    									{csUsers && csUsers.map((usr) =>
    									{
    										return (
    											<Dropdown.Item onClick={this.selectUser} id={usr.id}>{usr.name}</Dropdown.Item>
    										);
    									})}
    								</NavDropdown>
    							</Nav>
    						</th>
    						<th className="text-center">
    							<Nav>
    								<NavDropdown title={ callId != "all" ? callNumberToName(callId) : "All Calls" }>
    									<Dropdown.Item onClick={this.selectCallNo} id="all" >All Calls</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="1">Call 1</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="2">Call 2</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="3">Call 3</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="4">Call 4</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="5">Prospect Call 1</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="6">Prospect Call 2</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="7">Call Back 1</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="8">Call Back 2</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="9">Call Back 3</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="10">Auto Upsell 1</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="11">Auto Upsell 3</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="12">LL 8 Weeks</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="13">Revisit 1</Dropdown.Item>
    									<Dropdown.Item onClick={this.selectCallNo} id="14">Revisit 2</Dropdown.Item>
    								</NavDropdown>
    							</Nav>
    						</th>
    						<th className="text-center">Call Rec</th>
    						<th className="text-center">Call Duration</th>
    						<th className="text-center">Call Time</th>
    						<th className="text-center">
    							<Nav>
    								<NavDropdown title={ outcomeId != "all" ?  callOutcomes[outcomeId] : "Outcome" }>
    									<Dropdown.Item onClick={this.selectOutcome} id="all" >Outcome</Dropdown.Item>

    									{callOutcomes.map((val, key) =>
    									{
    										return (
    											<Dropdown.Item onClick={this.selectOutcome} id={key} key={key}>{val}</Dropdown.Item>
    										);
    									})}
    								</NavDropdown>
    							</Nav>
    						</th>
    						{includeQA && <th className="text-center">AI QA</th>}
    					</tr>
    				</thead>
    				<tbody align="center">

    					{callsMade.map((csCall) =>
    					{

    						return (
    							<tr className={csCall.outcome == "ANSWERED_VALS" ? "table-success" : undefined} key={csCall.id}>
    								<td><a href={adminPanelURL(`/vendor/property/${csCall.vendor_prop_id}/edit`)} target="_blank">{csCall.vendor_prop_id}</a></td>
    								<td>{csCall.name}</td>
    								<td>{callIcons(csCall.call_type)}</td>
    								<td >
    									{csCall.recording &&
                                <audio className="audio-css" controls="controls" preload="none" volume="1.0">
                                    Your browser does not support the <code>audio</code> element.
                                	<source src={csCall.recording} type="audio/mp3" />
                                </audio>
    									}
    								</td>
    								<td >
    									{csCall.duration && durationString(csCall.duration)}
    								</td>
    								<td>
    									{moment(from).isSame(to, "day") ? moment(csCall.called_at).format("HH:mm") : moment(csCall.called_at).format("HH:mm Do MMM")}
    								</td>
    								<td>{csCall.outcome}</td>
    								{includeQA && csCall.total_score && (
    									<td
    										onClick={() => this.setState({ modalQACall: csCall })}
    										style={{
    											backgroundColor: "rgba(" + (255/40)*(40 - csCall.total_score) + "," + (255/40) * csCall.total_score + ",0,0.7)",
    											cursor: "pointer"
    										}}
    									>
    										{csCall.total_score}
    									</td>)}
    							</tr>
    						);
    					})}
    				</tbody>
    			</Table>
    			{renderCallQAModal()}
    		</>
    	);
    }
}

export default FujisanCalls;