import React, { useState, useEffect } from "react";
import { Row, Col, FormLabel, FormGroup, FormControl, Button } from "react-bootstrap";
import { postAPI } from "utils/requestAPI";

const CsViewCallTransfer = ({
	vendorPropId,
	branches,
	userId,
	clientCallId,
}) =>
{
	const [branch, setBranch] = useState("select");
	const [isTransferrable, setIsTransferrable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const transfer = async(onError, onSuccess, isPreflight) =>
	{
		setIsLoading(true);

		try
		{

			await postAPI(
				"/vendor/phone-webhook/forward/transferStart?preflight=" + (isPreflight ? "1" : "0"),
				{ parentSid: clientCallId, vpId: vendorPropId, uId: userId, bId: branch }
			);

			if(onSuccess)
				onSuccess();
		}
		catch (err)
		{
			setIsTransferrable(false);
			onError(err.name === "Error" ? err.message : "An unknown error occurred. Transfer not possible");
		}

		setIsLoading(false);
	};

	const checkTransferEligibility = async() =>
	{
		setIsTransferrable(false);

		await transfer(
			setError,
			() =>
			{
				setError(null);
				setIsTransferrable(true);
			},
			true
		);
	};

	useEffect(() =>
	{
		if(branch === "select")
			return;

		checkTransferEligibility();
	}, [branch]);

	const onTransfer = async() =>
	{
		const onError = (err) =>
		{
			setError(err);
			alert(err);
		};

		// No need for a success callback here as the component will be unmounted if transfer was successful
		await transfer(onError);
	};

	const renderSubheading = () =>
	{
		if(branch === "select" || isTransferrable)
		{
			return (
				<span className="small">
					<strong>Remember:</strong>
					{" "}
					{isTransferrable ? "Clicking the green button will end your call with the vendor. Say your goodbyes" : "Select agent before offering transfer to check transfer eligibility"}
				</span>
			);
		}

		if(error)
		{
			return (
				<span className="text-danger small">
					{error}
				</span>
			);
		}
	};

	const getButtonText = () =>
	{
		if(isLoading && !isTransferrable)
			return "Checking eligibility";

		if(isLoading && isTransferrable)
			return "Transferring";

		if(isTransferrable || branch === "select")
			return "Transfer";

		return "Re-check eligibility";
	};

	return (
		<FormGroup>
			<FormLabel>Transfer vendor to:</FormLabel>
			<Row>
				<Col className="pr-0">
					<FormControl as="select" onChange={(e) => setBranch(e.target.value)} value={branch}>
						<option value="select">Select one</option>
						{branches.map((b) => <option value={b.id} key={b.id}>{b.name}</option>)}
					</FormControl>
					{renderSubheading()}
				</Col>
				<Col>
					<Button
						disabled={branch === "select" || isLoading}
						variant={isTransferrable ? "success" : "danger"}
						onClick={isTransferrable ? onTransfer : checkTransferEligibility}
					>
						{getButtonText()}
					</Button>
				</Col>
			</Row>
		</FormGroup>
	);
};

export default CsViewCallTransfer;
