import React from "react";
import {Card, Row, Col, FormLabel, FormGroup, FormControl, Button, Badge, InputGroup, Alert } from "react-bootstrap";
import CallBox from "../shared/callBox";
import CsPotentialListings from "./csPotentialListings";
import { FaHeadphonesAlt } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import { GAConfigContext } from "contexts/gaConfigContext";
import moment from "moment";
import _ from "underscore";
import PhoneNumber from "awesome-phonenumber";
import { gaNiceFormatPrice} from "utils/common";
import NoteInput from "../shared/noteInput";
import VendorListingsEdit from "./vendorListingsEdit";
import ShortlistEdit from "./shortlistEdit";
import MyPropertyTracker from "./myPropertyTracker";
import VendorTimeline from "./vendorTimeline";
import PriorAgents from "./priorAgents";
import ExchangeReferral from "./exchangeReferral";
import DocumentTitle from "../shared/documentTitle";
import { getAPI, postAPI } from "utils/requestAPI";
import { publicURL, adminPanelURL } from "utils/common";
import logError from "utils/logError";
import CsCallScripts from "./CsCallScripts";
import CsViewCallTransfer from "./csViewCallTransfer";

class CsView extends React.PureComponent
{
	constructor(props)
	{
		super(props);

		this.state = {
			vendorProp: null,
			loading: true,
			callOpen: false,
			callInProgress: false,
			statusText: "Mounting Component",
			callOutcome: "DID_NOT_ANSWER",
			whyNoVal: "OTHER",
			archiveState: 0,
			callNote: null,
			callingNextVendor: null,
			showPauseButton: false,
			shouldStopAutoCaller: true,
			csViewSafePlace: false,
			csCallId: null,
			calledAt: null,
			showReminder: false,
			reminderTitle: "Next Call At:",
			clientCallId: null,
			callType: "Unknown",
			timerCounter: 0,
			timer: null,
			twilioToken: null,
			CsCallScripts: [],
		};

		this._shortlistWindow = null;

		this._callOutcomes = {
			DID_NOT_ANSWER: "Didn't answer",
			ANSWERED_VALS: "Answered, got vals",
			ANSWERED_NO_VALS: "Answered, no vals",
			ANSWERED_CALL_BACK: "Answered, call back",
			NOT_FOUND: "Call failed: Not Found",
			ADDRESS_INCOMPLETE: "Call failed: Address Incomplete",
			WRONG_NUMBER: "Wrong number"
		};

		this._noValReasons = {
			ALREADY_CHOSEN: "Already chosen a new agent, not switching",
			ALREADY_LIVE: "Already live with agent, may switch in future",
			ALREADY_CONTACTED: "Already contacted agents",
			GO_DIRECT: "Wants to go direct",
			UNKNOWN_REASON: "Said no/hung up",
			PROBATE: "Probate",
			NOT_READY: "Not ready",
			NOT_SELLING: "Not selling",
			OTHER: "Other"
		};


		this._hoursToSnoozeUntilNextCall = [1, 1, 24, 24, 48];
		// This is how many till the next call.
		this._origHoursToSnoozeUntilNextCall = [1, 1, 24, 24, 48];

		this._csTeam = {id: 2, name: "CS Team", isAdminTeam: true};


		this.callTypeMapping = {
			READY_FOR_VALS_1: "Call 1",
			READY_FOR_VALS_2: "Call 2",
			READY_FOR_VALS_3: "Call 3",
			READY_FOR_VALS_4: "Call 4",
			READY_FOR_VALS_5: "Call 5",
			PROSPECT_READY_FOR_VALS_1: "Prospect Call 1",
			PROSPECT_READY_FOR_VALS_2: "Prospect Call 2",
			CALL_BACK_1: "Call Back 1",
			CALL_BACK_2: "Call Back 2",
			CALL_BACK_3: "Call Back 3",
			LL_8_WEEK: "Live Listing - 8 weeks",
			AUTO_UPSELL_1: "Automised Upsell",
			AUTO_UPSELL_2: "Automised Upsell 2",
			REVISIT_1: "Revisit 1",
			REVISIT_2: "Revisit 2",
		};

		this.autoCaller = this.autoCaller.bind(this);
		this.timer = this.timer.bind(this);
		this.getTwilioToken = this.getTwilioToken.bind(this);
		this.reloadVendor = this.reloadVendor.bind(this);

	}

	static contextType = GAConfigContext;

	componentDidMount()
	{
		this.getTwilioToken();
		this.getScripts();

		this._noValReasonToArchiveState = {
			ALREADY_CHOSEN: this.context.GA_CONFIG.vp.archiveStatus.ALREADY_CHOSEN_AGENT,
			ALREADY_LIVE: 0,
			ALREADY_CONTACTED: 0,
			GO_DIRECT: 0,
			UNKNOWN_REASON: 0,
			PROBATE: this.context.GA_CONFIG.vp.archiveStatus.HOMEOWNER_SELLING_LATER_THREE_MONTHS,
			NOT_READY: this.context.GA_CONFIG.vp.archiveStatus.HOMEOWNER_SELLING_LATER_THREE_MONTHS,
			NOT_SELLING: this.context.GA_CONFIG.vp.archiveStatus.HOMEOWNER_NOT_SELLING,
			OTHER: 0
		};

		// Do not lock a vendor

		this.setState({
			csViewSafePlace: true,
			loading: false
		});
	}

	componentDidUpdate(_, prevState)
	{
		if(!this.state.callInProgress && prevState.callInProgress)
			this.autoCaller();

	}


	getTwilioToken()
	{
		getAPI("/admin/twilioToken.json")
			.then(({ twilioToken }) => this.setState({ twilioToken }))
			.catch(e => console.log(e));
	}

	getScripts()
	{
		getAPI("/admin/csCallScripts.json")
			.then((res) => this.setState({ CsCallScripts: res.data }))
			.catch(e => console.log(e));
	}

	timer()
	{
		// function that counts down and moves to next call
		if(this.state.timerCounter > 0)
		{
			this.setState({ timerCounter: this.state.timerCounter - 1 }, () =>
			{
				// use setState callback to insure timer is updated
				if(this.state.timerCounter <= 0)
				{
					clearInterval(this.state.timer); // clear this from being called again
					this.setState({ showPauseButton: false, timer: null }); // stop showing the pause button
					this.saveCallLogAndMoveToNext(); // save this call and move on
				}
			});
		}

	}

	autoCaller()
	{
		// If this call is not in progress or the pause auto caller is not enabled
		if(!this.state.callInProgress && !this.state.shouldStopAutoCaller && this.state.callOutcome === "DID_NOT_ANSWER")
		{
			// make sure timer not already running
			if(this.state.timer)
				clearInterval(this.state.timer);

			this.setState({
				timerCounter: 3, // count down 3 seconds
				showPauseButton: true,
				timer: setInterval(this.timer, 1000) // 1 sec timer
			});
		}
	}


	pauseAutoCaller()
	{
		if(this.state.timer)
			clearInterval(this.state.timer);

		this.setState({
			showPauseButton: false,
			timer: null
		});
	}

	stopAutoCaller()
	{
		if(this.state.timer)
			clearInterval(this.state.timer);

		this.setState({
			shouldStopAutoCaller: !this.state.shouldStopAutoCaller,
			showPauseButton: false,
			timer: null
		});
	}


	loadNextCall()
	{
		this.setState({
			loading: true,
			statusText: "Fetching next call",
			callOpen: false,
			whyNoVal: "OTHER",
			archiveState: 0,
			callOutcome: "DID_NOT_ANSWER",
			callNote: null,
			phoneNumChanged: false,
			csViewSafePlace: false,
			showReminder: false,
			clientCallId: null
		});

		if(this.state.timer)
			throw new Error("Autocaller timer is already running when moving to next call");

		getAPI("/admin/vendor/customerService/nextCall")
			.then(data =>
			{
				if((! data.id) || (! data.type))
					throw new Error(data.msg || "Something went wrong, please refresh the page to try again");


				this.setState({statusText: "Loading vendor", csCallId: data.csCallId, callType: this.callTypeMapping[data.type]});

				return getAPI("/admin/vendor/property/" + data.id + "/edit.json");
			})
			.then(data =>
			{

				this.setState({
				loading: false,
				vendorProp: data.vendorProp,
				relatedVendorProps: null
				});

				if(this._shortlistWindow && (! this._shortlistWindow.closed))
					this._shortlistWindow.location = publicURL("/vendor/quotes/" + data.vendorProp.id);
				else
					this._shortlistWindow = window.open(publicURL("/vendor/quotes/" + data.vendorProp.id), "_blank");


				if(Math.abs(moment(data.vendorProp.created_at).diff(moment(data.vendorProp.user.created_at), "seconds")) > 30)
				{
				// load other related properties
					getAPI("/admin/vendor/property/search/" + data.vendorProp.user.email + ".json")
						.then((resp) =>
						{
							// remove this property
							this.setState({relatedVendorProps: _.filter(resp, p => p.id !== data.vendorProp.id)});
						})
						.catch(e =>
						{
							console.log(e);
							return;
						});
				}

				//////////////////////////////////////////////
				// Automating call as part of new CS View  //
				////////////////////////////////////////////

				if(this.state.vendorProp.referrer != "which")
					this.callVendor();
				else if(this.state.vendorProp.referrer == "which")
					this.checkTps();


			})
			.catch(err =>
			{
				alert(err.message);
				console.error(err);
				window.location.reload();
			});
	}

	reloadVendor()
	{
		if((! this.state.vendorProp ) || (! this.state.vendorProp.id))
			throw new Error("Reload called when there is no vendor loaded");

		this.setState({
			loading: true,
			statusText: "Reloading vendor"
		});


		getAPI("/admin/vendor/property/" + this.state.vendorProp.id + "/edit.json")
			.then(data =>
			{

				this.setState({
				loading: false,
				vendorProp: data.vendorProp
				});

			});
	}

	callVendor()
	{
		this.setState({
				callOpen: true,
				callInProgress: true,
				loading: false
		});
	}

	checkTps()
	{
		// check tps
		this.setState({
			loading: true,
			statusText: "Checking TPS",
			shouldStopAutoCaller: false
		});

		return getAPI("/admin/vendor/property/" + this.state.vendorProp.id + "/tpsCheck")
			.then((data) =>
			{

				if(data.tps)
				{
					return this.setState({
					callOutcome: "WHICH_LEAD_ON_TPS",
					archiveState: null,
					loading: false,
					clientCallId: null
					}, this.saveCallLogAndMoveToNext);
				}
				else
				{
					this.setState({loading: false});

					return this.callVendor();
				}
			})
			.catch(e =>
			{
				alert(e.message || "An unknown error occurred when checking the TPS register");

				return this.setState({
					callOutcome: "WHICH_LEAD_ON_TPS_ERROR",
					archiveState: null,
					loading: false,
					clientCallId: null
				}, this.saveCallLogAndMoveToNext);

			});
	}

	getCSCalls()
	{
		if((! this.state.vendorProp) || (! this.state.vendorProp.csCalls))
			return [];


		return this.state.vendorProp.csCalls;
	}

	callOutcomeChanged(e)
	{

		if(this.state.timer)
			this.pauseAutoCaller();

		const showReminder = ["ANSWERED_CALL_BACK"];

		if(showReminder.includes(e.target.value))
		{
			this.setState({
				callOutcome: e.target.value,
				showReminder: true
			});
		}
		else
		{
			this.setState({
				callOutcome: e.target.value,
				showReminder: false
			});
		}
	}

	whyNoValChanged(e)
	{
		let archiveState = 0;

		if(this.state.timer)
			this.pauseAutoCaller();

		if(this._noValReasonToArchiveState[e.target.value])
			archiveState = this._noValReasonToArchiveState[e.target.value];

		this.setState({
			whyNoVal: e.target.value,
			archiveState: archiveState
		});
	}

	archiveStateChanged(e)
	{
		if(this.state.timer)
			this.pauseAutoCaller();

		this.setState({archiveState: e.target.value});
	}

	callNoteChanged(noteState)
	{
		if(this.state.timer)
			this.pauseAutoCaller();

		this.setState({callNote: noteState});
	}

	onCallEnd(type, failureCause, callTime)
	{
		if(failureCause == "Address Incomplete")
			this.setState({callOutcome: "ADDRESS_INCOMPLETE"});

		else if(failureCause == "Not Found")
			this.setState({callOutcome: "NOT_FOUND"});

		if((! failureCause) && (callTime > 30))
			this.setState({callOutcome: "ANSWERED_NO_VALS", timer: clearInterval(this.state.timer)});

		this.setState({callInProgress: false});
	}

	onCallStart()
	{
		this.setState({
			callInProgress: true,
			calledAt: moment().utc().format("YYYY-MM-DD HH:mm:ss")
		});
	}

	onPhoneNumberChange(e)
	{
		if(this.state.timer)
			this.pauseAutoCaller();

		const user = _.extend({}, this.state.vendorProp.user, {mobile: e.target.value});
		const vendorProp = _.extend({}, this.state.vendorProp, {user: user});

		this.setState({
			vendorProp: vendorProp,
			phoneNumChanged: true
		});
	}

	onPhoneNumberSave()
	{
		if(this.state.timer)
			this.pauseAutoCaller();

		this.setState({
			loading: true,
			statusText: "Updating number"
		});

		postAPI("/admin/user/" + this.state.vendorProp.user.id + "/edit?patch=1", {mobile: this.state.vendorProp.user.mobile})
			.then((data) =>
			{
				this.setState({
				loading: false,
				phoneNumChanged: false
				});
			})
			.catch(e =>
			{
				throw new Error(e || "An error occurred when updaing mobile number");
			});
	}

	saveCallLogAndMoveToNext = () =>
	{

		// CS might sometimes click the button instead of waiting for the interval so we make sure
		// we clear the interval to prevent cutting next call due to interval lapsing
		this.pauseAutoCaller();

		const {
			callNote,
			csCallId,
			callOutcome,
			whyNoVal,
			calledAt,
			archiveState,
			vendorProp,
			clientCallId,
		} = this.state;

		let followUp;

		if(!csCallId)
		{

			logError("No csCallId", "Saving call details", {vpId: vendorProp.id, state: this.state});
			alert(`No CS Call ID found. Please refresh and try again. \n ${JSON.stringify(this.state)}`);
			return;
		}

		if(!callOutcome)
		{
			logError("No callOutcome", "Saving call details", {vpId: vendorProp.id, state: this.state});
			alert("Error: trying to save call log details with no outcome information. Please pick an outcome and try again.");
			return;
		}

		/*
			Here lies a piece of old code that we decided to remove. clientCallId started to mysteriously be null for some calls. We think it's some issue with Twilio as we didn't change anything on our end. We investigated and weren't sure why this is happening. Remove this if statement allowed the CS team to set the outcome and move on to their next call. We decided that this was ok to leave as is for now.

			If clientCallId isn't available the call entity won't be linked to the created csCall which means it won't be linked in the vendor's profile. We decided this was also ok because these calls tend to be "NOT_ANSWERED" etc so we're not losing a recording
		*/
		// if(!clientCallId && (callOutcome !== "WHICH_LEAD_ON_TPS" && callOutcome !== "WHICH_LEAD_ON_TPS_ERROR"))
		// 	alert(`PLEASE COPY AND PASTE THIS ENTIRE MESSAGE INTO AN EMAIL TO bugs@getagent.co.uk SO THE DEV TEAM CAN INVESTIGATE. MAKE SURE THAT YOU COPY THE ENTIRE TEXT. DONT JUST TAKE A SCREENSHOT AS THIS THIS WONT HELP US. ${JSON.stringify(this.state)}`);

		const csCallObj = {
			outcome: callOutcome,
			called_at: calledAt,
			called_by: this.context.GA_CONFIG.user.id,
		};

		//If reminder is set then next call will be a call back.
		if(callNote && (callOutcome === "ANSWERED_CALL_BACK"))

		{
			followUp = moment(callNote.reminderDate + " " + callNote.reminderTime, "YYYY-MM-DD H:mm").format("YYYY-MM-DD HH:mm:ss");

			if(!moment(followUp).isValid())
			{
				alert("Please enter a valid date for the callback to this vendor!");
				return;
			}
		}

		//Only set the why no vals if the outcome was this.
		if(callOutcome == "ANSWERED_NO_VALS")
			csCallObj.why_no_vals = whyNoVal;

		// save note
		this.setState({
			loading: true,
			statusText: "Saving note"
		});

		let noteObj = {};

		//Will Create a note only if it there
		if(callNote && callNote.note)
		{

			noteObj = {
				note: callNote.note,
				vendor_prop_id: vendorProp.id,
				creator_id: this.context.GA_CONFIG.user.id
			};

		}

		const archiveVendor = archiveState > 0 ? archiveState : null;


		postAPI("/admin/vendor/property/csCall/finished",
			{
			noteObj,
			csCallObj,
			followUp,
			csId: csCallId,
			vpId: vendorProp.id,
			archiveVendor,
			clientCallId
			})
			.then(res =>
			{
				if(!res.success)
					throw new Error(res.msg || "An error occurred when saving");

				this.setState({csViewSafePlace: true,});

				if(!this.state.shouldStopAutoCaller)
					this.loadNextCall();
				else
					window.location.reload();

			})
			.catch(err =>
			{
				// hide the loading message component since the save failed
				this.setState({
					loading: false,
					statusText: ""
				});

				alert(err.message);
				console.error(err);
			});


	}

	setCallClientId(id)
	{
		this.setState({ clientCallId: id });
	}


	render()
	{


		const isNumberValid = () =>
		{
			if(! this.state.vendorProp)
				return false;

			try
			{
				const phoneNumber = new PhoneNumber(this.state.vendorProp.user.mobile, "GB");

				return phoneNumber.isValid();
			}
			catch (err)
			{
				return false;
			}

		};

		if(!this.state.loading && this.state.csViewSafePlace)
		{
			return (
				<div className="text-center">
					<DocumentTitle title="Customer service" />

					<h2 className="text-center my-5">Welcome to the Customer Service View</h2>
					<Button onClick={this.loadNextCall.bind(this)} variant="success" disabled={this.state.loading} size="lg" className="p-3">Start Automated Calling</Button>
				</div>
			);
		}

		if(this.state.loading && (!this.state.vendorProp) && (!this.state.csViewSafePlace))
			return (<Alert variant="info">{"Loading - " + this.state.statusText}</Alert>);


		return (

			<div>
				{this.state.loading ? (
					<Alert variant="info">
						<strong>Loading</strong> {this.state.statusText || "Patience old chap"}
					</Alert>
				) : null}

				{this.state.relatedVendorProps ? (
					<Alert variant="warning">
				Watch out - this vendor has other properties:
						<ul>
							{this.state.relatedVendorProps.map(vp =>
							{
								return (
									<li key={vp.id}>
										<a href={adminPanelURL("/vendor/property/" + vp.id + "/edit")} target="_blank">{vp.address}, {vp.postcode}</a>
									</li>
								);
							})}
						</ul>
					</Alert>
				) : null}


				<h1 >{this.state.vendorProp.user.name}&nbsp;
				(<a href={adminPanelURL("/vendor/property/" + this.state.vendorProp.id + "/edit")} target="_blank">{this.state.vendorProp.id}</a>)
				&nbsp;
					<Button onClick={this.reloadVendor.bind(this)}>
						<FiRefreshCcw />
					</Button>

				&nbsp;

					<Badge variant={this.state.callType === this.callTypeMapping.LL_8_WEEK ? "success" : "primary"} className="float-right"><FaHeadphonesAlt style={{marginRight: "5px"}} />{this.state.callType}</Badge>
				</h1>

				<hr />

				{/* this check is needed for Twillio Call Box mount / unmount */}

				{this.state.callOpen ? (
					<Card>
						<Card.Body>
							<Row>
								<Col md={6}>
									<CallBox vendorProp={this.state.vendorProp}
										twilioToken={this.state.twilioToken}
										callImmediately={true}
										onCallEnd={this.onCallEnd.bind(this)}
										onCallStart={this.onCallStart.bind(this)}
										setCallClientId={this.setCallClientId.bind(this)}
									/>
									{this.state.callInProgress && (this.state.csCallId % 2 === 0) ? (
										<CsViewCallTransfer
											vendorPropId={this.state.vendorProp.id}
											branches={this.state.vendorProp.branches.filter(b => b.admins.length)}
											userId={this.context.GA_CONFIG.user.id}
											clientCallId={this.state.clientCallId}
										/>
									) : null}
								</Col>
								<Col md={6}>
									<FormGroup>
										<FormLabel>Call Outcome:</FormLabel>
										<FormControl as="select" placeholder="select" onChange={this.callOutcomeChanged.bind(this)} value={this.state.callOutcome}>
											{_.map(this._callOutcomes, (o, k) => <option value={k} key={k}>{o}</option>)}
										</FormControl>
									</FormGroup>
									{this.state.callOutcome === "ANSWERED_NO_VALS" && (
										<Row>
											<Col md={6}>
												<FormGroup>
													<FormLabel>Why no vals?</FormLabel>
													<FormControl as="select" placeholder="select" onChange={this.whyNoValChanged.bind(this)} value={this.state.whyNoVal}>
														{_.map(this._noValReasons, (o, k) => <option value={k} key={k}>{o}</option>)}
													</FormControl>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<FormLabel>Archive as:</FormLabel>
													<FormControl as="select" placeholder="select" onChange={this.archiveStateChanged.bind(this)} value={this.state.archiveState}>
														<option value="0" key="0">Do not archive</option>
														<option value="-1" key="-1" disabled>------------</option>
														{_.map(this.context.GA_CONFIG.vp.archiveStatus, (o, k) => <option value={o} key={o}>{k}</option>)}
													</FormControl>
												</FormGroup>
											</Col>
										</Row>
									)}

									<div style={{marginBottom: "15px"}}>
										<NoteInput
											defaultRemindTo={this._csTeam}
											csView={true}
											defaultRemindToAdminTeam
											vendorPropId={this.state.vendorProp.id}
											allowReminder={this.state.showReminder}
											reminderTitle={this.state.reminderTitle}
											hideAddButton
											onChangeNote={this.callNoteChanged.bind(this)}
											optionToStopAutoCaller={true}
											isChecked = {this.state.shouldStopAutoCaller}
											autoCallerHandler={this.stopAutoCaller.bind(this)}
										/>
									</div>

									{this.state.callOutcome === "ANSWERED_NO_VALS" && (
										<FormGroup>
											<Button onClick={this.saveCallLogAndMoveToNext.bind(this,999999)} disabled={this.state.callInProgress || this.state.loading} variant="danger">Save Call Details</Button>
										</FormGroup>
									)}

									{((this.state.callOutcome === "DID_NOT_ANSWER") || (this.state.callOutcome === "ANSWERED_CALL_BACK")) && (
										<FormGroup>
											<Button onClick={this.saveCallLogAndMoveToNext.bind(this)} disabled={this.state.callInProgress || this.state.loading} variant="primary">Save Call Details</Button>

											{/** hides or shows the pause button for autocaller */}

											{this.state.showPauseButton && <Button onClick={this.pauseAutoCaller.bind(this)} className="ml-2">Pause Auto Caller: {this.state.timerCounter}</Button>}
										</FormGroup>
									)}

									{(this.state.callOutcome === "ANSWERED_VALS") && (
										<FormGroup>
											<h1>YES BRUV</h1>
											<Button onClick={this.saveCallLogAndMoveToNext.bind(this,99)} disabled={this.state.callInProgress || this.state.loading} variant="success">Give me my next vendor</Button>
										</FormGroup>
									)}

									{((this.state.callOutcome === "NOT_FOUND") || (this.state.callOutcome === "ADDRESS_INCOMPLETE") || (this.state.callOutcome === "WRONG_NUMBER")) && (
										<FormGroup>
											<Button onClick={this.saveCallLogAndMoveToNext.bind(this,999999)} disabled={this.state.callInProgress || this.state.loading} variant="danger">Don't call again</Button>
										</FormGroup>
									)}

								</Col>
							</Row>
						</Card.Body>
					</Card>
				) : null }

				{this.state.vendorProp.referralProps[0] ? (
					<ExchangeReferral name={this.state.vendorProp.referralProps[0].referrer.name} />
				) : null}

				<Row className="my-4">
					<Col sm={3}>
						<FormGroup>
							<FormLabel>
							Address:
							</FormLabel>
							<div className="form-control-plaintext">
								{this.state.vendorProp.address1 + ", "+ this.state.vendorProp.postcode.toUpperCase()}
							</div>
						</FormGroup>
					</Col>
					<Col sm={3}>
						<FormGroup>
							<FormLabel>
							Beds &amp; Value:
							</FormLabel>
							<div className="form-control-plaintext">
								{this.state.vendorProp.bedrooms} bed &bull; &pound; {gaNiceFormatPrice(this.state.vendorProp.estvalue)}
							</div>
						</FormGroup>
					</Col>
					<Col sm={3}>

						<FormGroup>
							<FormLabel>
							Telephone:
							</FormLabel>
							<InputGroup>
								<FormControl type="text"
									value={this.state.vendorProp.user.mobile}
									onChange={this.onPhoneNumberChange.bind(this)}
									className={isNumberValid() ? "": "is-invalid"}
								/>
								<InputGroup.Append>
									<Button onClick={this.onPhoneNumberSave.bind(this)} variant="outline-secondary" disabled={! this.state.phoneNumChanged}>Save</Button>
								</InputGroup.Append>

							</InputGroup>
						</FormGroup>

					</Col>
					<Col sm={3}>
						<FormGroup>
							<FormLabel>
							Email:
							</FormLabel>
							<div className="form-control-plaintext">
								{this.state.vendorProp.user.email}
							</div>
						</FormGroup>
					</Col>

				</Row>

				{this.state?.CsCallScripts?.length ? <CsCallScripts scripts={this.state.CsCallScripts} callType={this.state.callType} /> : null}

				<CsPotentialListings id={this.state.vendorProp.id} className="mb-3" />

				<VendorListingsEdit vendorProp={this.state.vendorProp} reloadVendor={this.reloadVendor} hideIfNoListings={true} className="mb-3" />

				<VendorTimeline vendorProp={this.state.vendorProp} reloadVendor={this.reloadVendor} defaultRemindTo={this._csTeam} className="mb-3" />

				<PriorAgents agents={this.state.vendorProp.priorAgents} shortlist={this.state.vendorProp.branches} />

				<ShortlistEdit vendorProp={this.state.vendorProp} reloadVendor={this.reloadVendor} className="mb-3" />

				<MyPropertyTracker vendorProp={this.state.vendorProp} />

			</div>
		);
	}
}

export default CsView;
